/* eslint-disable no-console */
// import { PayloadAction } from '@reduxjs/toolkit';
import { ConsultantActionType } from 'store/action-types';
import { ConsultantAction } from 'store/actions/vendor/auth/authAction';
// import { saveDataLocally } from '../../../../utils/helpers';

type InitialState = {
    consultantData: any;
};

const initialState: InitialState = {
    consultantData: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const authReducer = (state: InitialState = initialState, action: ConsultantAction) => {
    switch (action.type) {
        case ConsultantActionType.CONSULTANT_REGISTER:
            return {
                ...state,
            };

        case ConsultantActionType.CONSULTANT_LOGIN:
            return {
                ...state,
                consultantData: action.payload,
            };

        case ConsultantActionType.CONSULTANT_VERIFY_LOGIN_OTP:
            return {
                ...state,
                consultantData: { ...state.consultantData, otpVerify: action.payload },
            };
        // consultantAuth?.consultantData

        case ConsultantActionType.CONSULTANT_REFRESH_TOKEN:
            return {
                ...state,
                consultantData: action.payload,
            };

        case ConsultantActionType.CONSULTANT_REVOKE_TOKEN:
            return {
                ...state,
                consultantData: action.payload,
            };

        default:
            return state;
    }
};

export default authReducer;

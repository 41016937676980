import StaffAxios from '../config/Axios';

const useCustomFetchHook = () => {
    // eslint-disable-next-line consistent-return
    const getData = async (url: string) => {
        try {
            const response = await StaffAxios.get(url);
            if (response) {
                return response;
            }
        } catch (error: any) {
            return Promise.reject(error);
        }
    };

    // eslint-disable-next-line consistent-return
    const postData = async (url: string, payload: any) => {
        try {
            const response = await StaffAxios.post(url, payload);
            if (response) {
                return response;
            }
        } catch (error: any) {
            return Promise.reject(error);
        }
    };

    // eslint-disable-next-line consistent-return
    const editData = async (url: string, payload?: any) => {
        try {
            const response = await StaffAxios.put(url, payload);
            if (response) {
                return response;
            }
        } catch (error: any) {
            return Promise.reject(error);
        }
    };

    // eslint-disable-next-line consistent-return
    const deleteData = async (url: string) => {
        try {
            const response = await StaffAxios.delete(url);
            if (response) {
                return response;
            }
        } catch (error: any) {
            return Promise.reject(error);
        }
    };

    const useHttpProtocol = {
        getData,
        postData,
        editData,
        deleteData,
    };
    return useHttpProtocol;
};

export default useCustomFetchHook;

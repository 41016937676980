/* eslint-disable consistent-return */
import { Dispatch } from 'redux';
import useCustomFetchHook from 'hooks/useCustomFetchHook';
import store from 'store';
import { getAdminUser, notifyInfo, notifySuccess, removeLocalData } from 'utils/helpers';
import Config from 'config/config';
// import { AdminActionType } from './adminAuthAction';
// import { AdminActionType } from './adminAuthAction';
import AdminSideNavItems from 'components/admin-side-nav-items';
import { AdminAction, AdminActionType } from './adminAuthAction';
// const { dispatch } = store;

export const removeAdminLocalToken = (): any => (dispatch: Dispatch<AdminAction>) => {
    const token = 'appState-fcmb-sme-admin';
    if (localStorage.getItem(token)) removeLocalData(token);
    if (localStorage.getItem(token) === null) {
        dispatch({
            type: AdminActionType.ADMIN_LOGOUT,
            payload: null,
        });
    }
};

export const login = async (encryptedString: string, otp: string | null, step: string) => {
    try {
        const response = Config.isProduction
            ? await useCustomFetchHook().postData('users/staff-login', {
                  encryptedString,
                  otp,
                  step,
              })
            : await useCustomFetchHook().postData('users/staff-test-login', {
                  encryptedString,
                  otp,
                  step,
              });
        if (response) {
            return response?.data;
        }
    } catch (error: any) {
        return Promise.reject(error);
    }
};

export const navigateAdminOnLogin = (navigate: any, availablePermissions: any[]) => {
    try {
        const sortedAdminNavItems = [...AdminSideNavItems].reverse();
        sortedAdminNavItems.forEach((navItem) => {
            if (
                availablePermissions.some(
                    (perm) => perm.menuName.toLowerCase() === navItem.name.toLocaleLowerCase(),
                )
            ) {
                navigate(navItem.path);
            }
        });
    } catch (error: any) {
        console.log('error occured', error);
        return Promise.reject(error);
    }
};

export const logoutStaff = async (logoutType: string) => {
    const { adminUserData } = store.getState().adminAuth;

    const signedInUserData = getAdminUser(adminUserData?.accessToken);

    try {
        const response = await useCustomFetchHook().postData('users/staff-log-out', {
            email: signedInUserData?.sub,
        });
        if (response) {
            removeLocalData('appState-fcmb-sme-admin');
            if (localStorage.getItem('appState-fcmb-sme-admin') === null) {
                removeAdminLocalToken();
            }
            if (logoutType === 'signout') {
                notifySuccess('logout successfully');
            } else if (logoutType === 'timeout') {
                notifyInfo('Session timeout, login again to continue');
            }
        }
    } catch (error: any) {
        return Promise.reject(error);
    }
};

/* eslint-disable no-nested-ternary */
/* eslint-disable no-async-promise-executor */
// import Axios from '../../config/Axios';
import { Dispatch } from 'redux';
import Axios from '../../../../../config/Axios';
import { UnitAssignedBusinessRequestByConsultantActionType } from '../../../../action-types';
import store from '../../../../index';
import { uiStopLoading } from '../../../loading';
import { AssignedBusinessRequestDataByConsultantAction } from './assignedBusinessRequestAction';
// import * as actionTypes from '../actionTypes';

const getErrorMessage = (data: any): string => {
    const message = Object.values(data)[0] as string;
    return message;
};

const { dispatch: customDispatch } = store;

// eslint-disable-next-line import/prefer-default-export
export const getAssignedBusinessRequest =
    (assignedRequestId: string | undefined): any =>
    async (dispatch: Dispatch<AssignedBusinessRequestDataByConsultantAction>): Promise<any> =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await Axios.post(`get-assigned-business-request-by-id`, {
                    assignedRequestId,
                });
                // response?.status === 200 && response?.data?.data === null
                if (!response) {
                    resolve({
                        error: true,
                        message: 'business request detail could not be found',
                        data: 'no data',
                    });
                } else if (response?.status === 200 && response?.data?.code === '00') {
                    dispatch({
                        type: UnitAssignedBusinessRequestByConsultantActionType.UNITASSIGNEDUSINESSREQUEST,
                        payload: response?.data?.data,
                    });

                    resolve({
                        error: false,
                        message: 'Data retrieved',
                    });
                }
            } catch (error: any) {
                customDispatch(uiStopLoading());

                const errorData: any = {
                    error: true,
                    /* message:
                        error?.response?.status === 500
                            ? 'Internal Sever error'
                            : error?.response?.status === 400
                            ? getErrorMessage(error?.response?.data?.errors)
                            : 'Error occured, Please check your network', */
                };
                reject(errorData);
            }
        });

export const consultantBusinessRequestFeedback =
    (payload: any): any =>
    async (): Promise<any> =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await Axios.post(
                    `consultants-submit-business-request-feedback`,
                    payload,
                );

                if (!response) {
                    resolve({
                        error: true,
                    });
                } else {
                    resolve({
                        error: false,
                        message: response?.data?.description,
                        data: response?.data?.data,
                    });
                }
            } catch (error: any) {
                const errorData: any = {
                    error: true,
                    /* message:
                        error?.response?.status === 500
                            ? 'Internal Sever error'
                            : error?.response?.status === 400
                            ? getErrorMessage(error?.response?.data?.errors)
                            : 'Error occured, Please check your network', */
                };
                reject(errorData);
            }
        });

export const customerBusinessRequestReply =
    (payload: any): any =>
    async (): Promise<any> =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await Axios.post(
                    `customers-reply-business-request-feedback`,
                    payload,
                );
                if (!response) {
                    resolve({
                        error: true,
                        // message: response?.data?.description,
                    });
                } else {
                    resolve({
                        error: false,
                        message: response?.data?.description,
                        data: response?.data?.data,
                    });
                }
            } catch (error: any) {
                customDispatch(uiStopLoading());
                const errorData: any = {
                    error: true,
                    message:
                        error?.response?.status === 500
                            ? 'Internal Sever error'
                            : error?.response?.status === 400
                            ? getErrorMessage(error?.response?.data?.errors)
                            : 'Error occured, Please check your network',
                };
                reject(errorData);
            }
        });

export const getFeedbackDocumentsByFeedbackID =
    (payload: any): any =>
    async (): Promise<any> =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await Axios.post(`get-feedback-documents-by-feedback-id`, payload);
                if (!response) {
                    resolve({
                        error: true,
                        // message: response?.data?.description,
                    });
                } else {
                    resolve({
                        error: false,
                        message: response?.data?.description,
                        data: response?.data?.data,
                    });
                }
            } catch (error: any) {
                const errorData: any = {
                    error: true,
                    message:
                        error?.response?.status === 500
                            ? 'Internal Sever error'
                            : error?.response?.status === 400
                            ? getErrorMessage(error?.response?.data?.errors)
                            : 'Error occured, Please check your network',
                };
                reject(errorData);
            }
        });

export enum AdminActionType {
    ADMIN_LOGIN = 'ADMIN_LOGIN',
    ADMIN_LOGOUT = 'ADMIN_LOGOUT',
    ADMIN_REMEMBER_ME = 'ADMIN_REMEMBER_ME',
    ADMIN_REFRESH_TOKEN = 'ADMIN_REFRESH_TOKEN',
}

interface AdminLoginAction {
    type: AdminActionType.ADMIN_LOGIN;
    payload: any;
}

interface AdminLogoutAction {
    type: AdminActionType.ADMIN_LOGOUT;
}

interface AdminRefreshToken {
    type: AdminActionType.ADMIN_REFRESH_TOKEN;
    payload: any;
}

export type AdminAction = AdminLoginAction | AdminLogoutAction | AdminRefreshToken;

import { env } from '../env';

const config = {
    isProduction: env.REACT_APP_IS_PRODUCTION === 'true',
    SERVER_URL: env.REACT_APP_SERVER_URL,
    VERIFY_SERVER_URL: env.REACT_APP_VERIFY_SERVER_URL,
    BRANCH_CONSOLE: env.REACT_APP_BRANCH_CONSOLE,
    SAVE_AND_CONTINUE: env.REACT_APP_SAVE_AND_CONTINUE,
    subscription_key_name: env.REACT_APP_subscription_key_name,
    subscription_key_value: env.REACT_APP_subscription_key_value,
    x_correlation_id: env.REACT_APP_x_correlation_id,
    xtoken_password: env.REACT_APP_xtoken_password,
    client_id: env.REACT_APP_CLIENT_ID,
    product_id: env.REACT_APP_PRODUCT_ID,
    Ocp_Apim_Subscription: env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
};

export default config;

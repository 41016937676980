import { v4 as uuidv4 } from 'uuid';
import { ParentSideNavItemProps } from 'interfaces/sidebar-navitems.types';
import DashboardIcon from 'assets/icons/DashboardIcon';
import CustomerAcquisitionIcon from 'assets/icons/CustomerAcquisitionIcon';
import RequestIcon from 'assets/icons/RequestIcon';
import ConsultantIcon from 'assets/icons/ConsultantsIcon';
import PlatformIcon from 'assets/icons/PlatformIcon';
import SettingIcons from 'assets/icons/SettingIcon';
import ApprovalIcon from 'assets/icons/ApprovalIcon';
import ReportIcon from 'assets/icons/report';
import ReferenceIcon from 'assets/icons/Reference';

// FOR USAGE IN THE NEW ROUTES WRAPPERS
export enum AdminSideNavEnum {
    Dashboard = 'Dashboard',
    CustomerAcquisition = 'Customer acquisition',
    ServiceRequest = 'Service request',
    Consultants = 'Consultants',
    References = 'References',
    Reports = 'Reports',
    PlatformUpdate = 'Platform update',
    Approval = 'Approval',
    Settings = 'Settings',
}

const AdminSideNavItems: ParentSideNavItemProps[] = [
    {
        id: uuidv4(),
        nId: 1,
        name: 'Dashboard',
        path: '/admin',
        Icon: DashboardIcon,
    },

    {
        id: uuidv4(),
        nId: 2,
        name: 'Customer acquisition',
        path: '/admin/customer-acquisition',
        Icon: CustomerAcquisitionIcon,
    },
    {
        id: uuidv4(),
        nId: 3,
        name: 'Service request',
        path: '/admin/service-request',
        Icon: RequestIcon,
    },
    {
        id: uuidv4(),
        nId: 4,
        name: 'Consultants',
        path: '/admin/consultants',
        Icon: ConsultantIcon,
    },
    {
        id: uuidv4(),
        nId: 5,
        name: 'References',
        path: '/admin/references',
        Icon: ReferenceIcon,
    },
    {
        id: uuidv4(),
        nId: 6,
        name: 'Reports',
        path: '/admin/reports',
        Icon: ReportIcon,
    },

    {
        id: uuidv4(),
        nId: 7,
        name: 'Platform update',
        path: '/admin/platform-updates',
        Icon: PlatformIcon,
    },
    {
        id: uuidv4(),
        nId: 8,
        name: 'Approval',
        path: '/admin/approvals',
        Icon: ApprovalIcon,
    },
    {
        id: uuidv4(),
        nId: 9,
        name: 'Settings',
        path: '/admin/settings',
        Icon: SettingIcons,
    },
];

export default AdminSideNavItems;

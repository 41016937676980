import LoadingIndicator from 'components/onboarding/LoadingIndicator';
import React, { Suspense, lazy } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { useIdleTimer } from 'react-idle-timer';
import { timeout, debounce } from 'utils/constant';
import { AdminActionType } from 'store/actions/admin/auth/adminAuthAction';
import { logoutStaff } from 'store/actions/admin/auth/adminAuth';
import { notifyInfo } from 'utils/helpers';
import useLogout from './hooks/useLogout';
import { RootState } from './store';

const Routes = lazy(() => import('./routes'));

function App() {
    const {
        loading: { isLoading },
        adminAuth,
        consultantAuth,
    } = useSelector((store: RootState) => store);

    const dispatch = useDispatch();

    const logoutConsultant = useLogout();

    const { isLoggedIn } = adminAuth;
    const { consultantData } = consultantAuth;

    const logoutConsultantUser = async () => {
        if (!consultantData) return;
        try {
            await logoutConsultant();
            window.location.replace('/vendor/login');
        } catch (err) {
            localStorage.clear();
            notifyInfo('Session timeout');
            window.location.replace('/vendor/login');
        }
    };

    const logoutStaffUser = async () => {
        if (!isLoggedIn) {
            return;
        }
        try {
            await logoutStaff('timeout');
            window.location.replace('/staff/login');
        } catch (err) {
            localStorage.clear();
            if (localStorage.getItem('appState-fcmb-sme-admin') === null) {
                dispatch({
                    type: AdminActionType.ADMIN_LOGOUT,
                    payload: null,
                });
            }
            notifyInfo('Session timeout');
            window.location.replace('/staff/login');
        }
    };

    const initiateLogout = () => {
        const routeCheck = window.location.pathname.split('/')[1];
        if (routeCheck === 'vendor') {
            logoutConsultantUser();
        } else if (routeCheck === 'admin') {
            logoutStaffUser();
        }
    };

    const handleOnIdle = async () => {
        initiateLogout();
    };

    useIdleTimer({
        timeout,
        onIdle: handleOnIdle,
        debounce,
    });

    return (
        <Suspense fallback={<LoadingIndicator />}>
            {isLoading && <LoadingIndicator />}
            <Routes />
        </Suspense>
    );
}

export default App;

/* eslint-disable no-async-promise-executor */
import { Dispatch } from 'redux';
import store from '../../../index';
import Axios from '../../../../config/Axios';
import { ConsultantDetailsAction } from './consultantDetailAction';
import { ConsultantDetailsActionType } from '../../../action-types/index';
import { uiStopLoading } from '../../loading';

/* const getErrorMessage = (data: any): string => {
    const message = Object.values(data)[0] as string;
    return message;
}; */

const { dispatch: customDispatch } = store;

export const getConsultantDetails =
    (payload: string): any =>
    async (dispatch: Dispatch<ConsultantDetailsAction>) =>
        new Promise(async (resolve, reject) => {
            try {
                const controller = new AbortController();
                let result;
                /* {
                        signal: controller.signal
                    } */
                const response = await Axios.get(`Consultant-details?consultantUserId=${payload}`, {
                    signal: controller.signal,
                });

                if (
                    response?.status === 200 &&
                    response?.data?.code === '00' &&
                    response?.data?.data.length > 0
                ) {
                    dispatch({
                        type: ConsultantDetailsActionType.CONSULTANTDETAIL,
                        payload: response.data?.data[0],
                    });
                    result = {
                        error: false,
                        message: 'Data retrieved',
                    };
                } else if (
                    response?.status === 200 &&
                    response?.data?.code === '00' &&
                    response?.data?.data.length < 1
                ) {
                    result = {
                        error: true,
                        message: 'user detail not found',
                    };
                }
                resolve(result);
            } catch (error: any) {
                customDispatch(uiStopLoading());

                /* const errorData: any = {
                    error: true,
                    message:
                        error?.response?.status === 500
                            ? 'Internal Sever error'
                            : error?.response?.status === 400
                            ? getErrorMessage(error?.response?.data?.errors)
                            : 'Error occured, Please check your network',
                }; */
                const errorData: any = {
                    error: true,
                };
                reject(errorData);
            }
        });
export const testData = () => {};

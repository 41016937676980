/* eslint-disable no-console */
import { AdminActionType } from '../../../actions/admin/auth/adminAuthAction';

type InitialState = {
    isLoggedIn: boolean;
    adminUserData: any;
};

const initialState: InitialState = {
    isLoggedIn: false,
    adminUserData: undefined,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const authReducer = (state: InitialState = initialState, action: any) => {
    switch (action.type) {
        case AdminActionType.ADMIN_LOGIN:
            return {
                ...state,
                isLoggedIn: true,
                adminUserData: action.payload,
            };
        case AdminActionType.ADMIN_REFRESH_TOKEN:
            return {
                ...state,
                adminUserData: action.payload,
            };
        case AdminActionType.ADMIN_LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                adminUserData: undefined,
            };

        default:
            return state;
    }
};

export default authReducer;

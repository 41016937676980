/* eslint-disable no-async-promise-executor */

import { Dispatch } from 'redux';
import store from '../../index';
import { PopupActionType, PopupTwoActionType } from '../../action-types';
import { VerifyAction } from './helperAction';
import DocumentAxios from '../../../config/DocumentAxios';
import { uiStopLoading } from '../loading';

const { dispatch: customDispatch } = store;

export const verifyID =
    (payload: any): any =>
    async () =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await DocumentAxios.post(`id-verification`, payload);

                if (response?.status === 200 && response?.data?.code !== '00') {
                    resolve({
                        error: true,
                        // message: 'No data found for RC Number',
                    });
                }

                const { companyName } = response?.data?.data?.cac as any;
                const constactData = {
                    businessName: companyName,
                };
                resolve({
                    error: false,
                    message: response?.data?.description,
                    data: constactData,
                });
            } catch (error: any) {
                customDispatch(uiStopLoading());
                const errorData: any = {
                    error: true,
                    /* message:
                        error?.response?.status === 500
                            ? 'Internal Sever error'
                            : 'Error occured, Please check your network', */
                };
                reject(errorData);
            }
        });

export const verifyAccountNumber =
    (payload: any): any =>
    async () =>
        new Promise(async (resolve, reject) => {
            try {
                const response: any = await DocumentAxios.post(`account-details`, payload);
                if (!response) {
                    resolve({
                        error: true,
                        // message: response?.data?.description,
                    });
                } else {
                    resolve({
                        error: false,
                        message: response?.data?.description,
                        data: response?.data?.data,
                    });
                }
            } catch (error: any) {
                customDispatch(uiStopLoading());
                const errorData: any = {
                    error: true,
                    message:
                        error?.response?.status === 500
                            ? 'Internal Sever error'
                            : error?.response?.data?.description,
                };
                reject(errorData);
            }
        });
export const updatePopup =
    (data: any): any =>
    (dispatch: Dispatch<VerifyAction>) => {
        dispatch({
            type: PopupActionType.POPUP_STATE,
            payload: data,
        });
    };

export const updatePopupTwo =
    (data: any): any =>
    (dispatch: Dispatch<VerifyAction>) => {
        dispatch({
            type: PopupTwoActionType.POPUP_STATE_TWO,
            payload: data,
        });
    };

/* eslint-disable no-nested-ternary */
/* eslint-disable no-async-promise-executor */
// import Axios from '../../config/Axios';
import { Dispatch } from 'redux';
import Axios from 'config/Axios';
import { ConsultantActionType } from 'store/action-types';
import { removeLocalData } from 'utils/helpers';
import { uiStopLoading } from 'store/actions/loading';
import store from 'store';
import DocumentAxios from 'config/DocumentAxios';
import { ConsultantAction } from './authAction';

const getErrorMessage = (data: any): string => {
    const message = Object.values(data)[0] as string;
    return message;
};

const { dispatch: customDispatch } = store;

export const removeLocalToken = (): any => (dispatch: Dispatch<ConsultantAction>) => {
    const token = 'appState-fcmb-sme-admin';
    if (localStorage.getItem(token)) removeLocalData(token);
    if (localStorage.getItem(token) === null) {
        dispatch({
            type: ConsultantActionType.CONSULTANT_REVOKE_TOKEN,
            payload: null,
        });
    }
};
export const register =
    (payload: any): any =>
    async () => {
        try {
            let responseData;
            const response = await Axios.post(`users/consultant-register`, payload);
            /* if (response?.status === 200 && response?.data?.code === '00') {
                responseData = {
                    error: false,
                    message: response?.data?.description ?? 'Consultant successfully registered',
                };
            } else if (response?.status === 200 && response?.data?.code === '26') {
                responseData = {
                    error: true,
                    message: response?.data?.description,
                };
            } else if (response?.status === 200 && response?.data?.code === '23') {
                responseData = {
                    error: true,
                    message: response?.data?.description,
                };
            } */
            if (!response) {
                responseData = {
                    error: true,
                    // message: response?.data?.description,
                };
            } else {
                responseData = {
                    error: false,
                    message: response?.data?.description ?? 'Consultant successfully registered',
                };
            }

            return responseData;
        } catch (error: any) {
            customDispatch(uiStopLoading());
            return {
                error: true,
                message:
                    // eslint-disable-next-line no-nested-ternary
                    error?.response?.status === 500
                        ? 'Internal Sever error'
                        : error?.response?.status === 400
                        ? getErrorMessage(error?.response?.data?.errors)
                        : 'Error occured, Please check your network',
            };
        }
    };

export const login =
    (encryptedString: any): any =>
    async (dispatch: Dispatch<ConsultantAction>) => {
        try {
            const response = await Axios.post(`users/consultant-login`, encryptedString);
            /* if (response?.status === 200 && response?.data?.code === '00') {
                dispatch({
                    type: ConsultantActionType.CONSULTANT_LOGIN,
                    payload: response?.data?.data,
                });
            } else if (response?.status === 200 && response?.data?.code === '02') {
                return {
                    error: true,
                    message: response?.data?.description ?? 'Invalid email or password',
                };
            } else if (response?.status === 200 && response?.data?.code === '99') {
                return {
                    error: true,
                    message: response?.data?.description ?? 'Invalid email or password',
                };
            } else if (
                response?.status === 200 &&
                (response?.data?.code === '03' || response?.data?.code === '04')
            ) {
                return {
                    error: true,
                    message: response?.data?.description,
                };
            } */
            if (!response) {
                return {
                    error: true,
                    // message: response?.data?.description,
                };
            }

            dispatch({
                type: ConsultantActionType.CONSULTANT_LOGIN,
                payload: response?.data?.data,
            });

            return {
                error: false,
                message: 'Consultant successfully login',
            };
        } catch (error: any) {
            customDispatch(uiStopLoading());
            return {
                error: true,
                message:
                    error?.response?.status === 500
                        ? 'Internal Sever error'
                        : error?.response?.status === 400
                        ? getErrorMessage(error?.response?.data?.errors)
                        : 'Error occured, Please check your network',
            };
        }
    };

export const getConsultantLoginOTP =
    (payload: any): any =>
    async () => {
        try {
            const response = await DocumentAxios.post(`generate-email-otp`, payload);

            if (!response) {
                removeLocalData('appState-fcmb-sme-admin');
                if (localStorage.getItem('appState-fcmb-sme-admin') === null) {
                    await removeLocalToken();
                }
                return {
                    error: true,
                    // message: response?.data?.description ?? 'Internal Sever error',
                };
            }

            return {
                error: false,
                message: response?.data?.description ?? 'otp sent',
                data: response?.data?.data?.requestId,
            };
        } catch (error: any) {
            customDispatch(uiStopLoading());
            return {
                error: true,
                message:
                    error?.response?.status === 500
                        ? 'Internal Sever error'
                        : error?.response?.status === 400
                        ? getErrorMessage(error?.response?.data?.errors)
                        : error?.response?.data?.description,
            };
        }
    };

export const consultantIsVerifyDispatch = () => (dispatch: Dispatch<ConsultantAction>) => {
    dispatch({
        type: ConsultantActionType.CONSULTANT_VERIFY_LOGIN_OTP,
        payload: true,
    });
};
export const validateConsultantLoginOTP =
    (payload: any): any =>
    // eslint-disable-next-line consistent-return
    async () => {
        try {
            const response = await DocumentAxios.post(`validate-email-otp`, payload);
            /* if (response.status === 200 && response.data?.code === '00') {
                dispatch({
                    type: ConsultantActionType.CONSULTANT_VERIFY_LOGIN_OTP,
                    payload: true,
                });
            } else if (response.status === 200 && response.data?.code === '02') {
                return {
                    error: true,
                    message: response?.data?.description,
                };
            }
            if (response.status === 200 && response.data?.code === '99') {
                return {
                    error: true,
                    message: response?.data?.description,
                };
            } */
            if (!response) {
                return {
                    error: true,
                    // message: response?.data?.description,
                };
            }

            /* dispatch({
                type: ConsultantActionType.CONSULTANT_VERIFY_LOGIN_OTP,
                payload: true,
            }); */
            consultantIsVerifyDispatch();
            return {
                error: false,
                message: 'otp verified',
            };
        } catch (error: any) {
            customDispatch(uiStopLoading());
            return {
                error: true,
                message:
                    error?.response?.status === 500
                        ? 'Internal Sever error'
                        : error?.response?.status === 400
                        ? getErrorMessage(error?.response?.data?.errors)
                        : error?.response?.data?.description,
            };
        }
    };

export const refreshToken =
    (payload: any): any =>
    async (dispatch: Dispatch<ConsultantAction>) => {
        try {
            const response = await Axios.post(`users/consultant-refresh-token`, payload);

            /* if (response.status === 200 && response.data?.code === '00') {
                dispatch({
                    type: ConsultantActionType.CONSULTANT_REFRESH_TOKEN,
                    payload: response.data?.data,
                });
            } else if (response.status === 200 && response.data?.code === '26') {
                return {
                    error: false,
                    message: 'Unable to refresh token',
                };
            } */
            if (!response) {
                return {
                    error: false,
                    // message: 'Unable to refresh token',
                };
            }
            dispatch({
                type: ConsultantActionType.CONSULTANT_REFRESH_TOKEN,
                payload: response.data?.data,
            });
            return {
                error: false,
                message: 'Consultant successfully registered',
            };
        } catch (error: any) {
            customDispatch(uiStopLoading());
            return {
                error: true,
                message:
                    error?.response?.status === 500
                        ? 'Internal Sever error'
                        : error?.response?.status === 400
                        ? getErrorMessage(error?.response?.data?.errors)
                        : 'Error occured, Please check your network',
            };
        }
    };

export const logout =
    (email: string): any =>
    async (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await Axios.post(`users/consultant-revoke-token`, { email });

                /* if (response?.status === 200 && response?.data?.code === '00') {
                    removeLocalData('appState-fcmb-sme-admin');
                    if (localStorage.getItem('appState-fcmb-sme-admin') === null) {
                        removeLocalToken();
                    }

                    resolve({
                        error: false,
                        message: 'Consultant successfully logout',
                    });
                } else if (response?.status === 200 && response?.data?.code === '26') {
                    // notifySuccess("Login failed");
                    resolve({
                        error: true,
                        message: 'Unable to revoke token',
                    });
                } */
                if (!response) {
                    resolve({
                        error: true,
                        message: 'Unable to revoke token',
                    });
                } else {
                    removeLocalData('appState-fcmb-sme-admin');
                    if (localStorage.getItem('appState-fcmb-sme-admin') === null) {
                        await removeLocalToken();
                    }

                    resolve({
                        error: false,
                        message: 'Consultant successfully logout',
                    });
                }
            } catch (error: any) {
                customDispatch(uiStopLoading());
                const errorData: any = {
                    error: true,
                    message:
                        error?.response?.status === 500
                            ? 'Internal Sever error'
                            : error?.response?.status === 400
                            ? getErrorMessage(error?.response?.data?.errors)
                            : 'Error occured, Please check your network',
                };
                reject(errorData);
            }
        });
    };

export const forgetPassword =
    (payload: any): any =>
    async (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await Axios.post(`users/consultant-forgot-password`, payload);
                // response?.status === 200 && response?.data?.code !== '00'
                if (!response) {
                    resolve({
                        error: true,
                        /* message:
                            response?.data?.description ?? 'Unable to reset email address password', */
                    });
                } else {
                    resolve({
                        error: false,
                        message: response?.data?.description ?? 'OTP has been sent to your mail',
                        data: response?.data?.data,
                    });
                }
            } catch (error: any) {
                customDispatch(uiStopLoading());
                const errorData: any = {
                    error: true,
                    /* message:
                        error?.response?.status === 500
                            ? 'Internal Sever error'
                            : 'Error occured, Please check your network', */
                };
                reject(errorData);
            }
        });
    };

export const resetPassword =
    (encryptedString: any): any =>
    async (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await Axios.post(`users/consultant-reset-password`, {
                    encryptedString,
                });

                // response?.status === 200 && response?.data?.code !== '00'
                if (!response) {
                    resolve({
                        error: true,
                        // message: response.data?.description,
                    });
                } else {
                    resolve({
                        error: false,
                        message: response?.data?.description,
                        data: response?.data?.data,
                    });
                }
            } catch (error: any) {
                const errorData: any = {
                    error: true,
                    /* message:
                        error?.response?.status === 500
                            ? 'Internal Sever error'
                            : 'Error occured, Please check your network', */
                };
                reject(errorData);
            }
        });
    };

export const refreshTokenData =
    (payload: any): any =>
    (dispatch: Dispatch<ConsultantAction>) => {
        dispatch({
            type: ConsultantActionType.CONSULTANT_REFRESH_TOKEN,
            payload,
        });
    };

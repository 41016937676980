export const makeAPIAction = (actionName: string) => ({
    REQUEST: `@@API_${actionName}_REQUEST`,
    SUCCESS: `@@API_${actionName}_SUCCESS`,
    FAILURE: `@@API_${actionName}_FAILURE`,
});

export const UI_START_LOADING = 'UI_START_LOADING';
export const UI_STOP_LOADING = 'UI_STOP_LOADING';

// for auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';
export const CHECK_USER = 'CHECK_USER';
export const RESET_USER = 'RESET_USER';

// export const CONSULTANT_REGISTER = 'CONSULTANT_REGISTER';

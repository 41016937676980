/* eslint-disable no-console */
// import { PayloadAction } from '@reduxjs/toolkit';
import { PersistActionType } from '../../action-types';
import { PersistAction } from '../../actions/helper/persistAction';

type InitialState = {
    persistData: boolean;
};

const initialState: InitialState = {
    persistData: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const persistReducer = (state: InitialState = initialState, action: PersistAction) => {
    switch (action.type) {
        case PersistActionType.PERSISTAUTH:
            return {
                ...state,
                persistData: action.payload,
            };

        default:
            return state;
    }
};

export default persistReducer;

/* eslint-disable no-console */
// import { PayloadAction } from '@reduxjs/toolkit';
import { ConsultantDetailsAction } from '../../../actions/vendor/business/consultantDetailAction';
import { ConsultantDetailsActionType } from '../../../action-types';

type InitialState = {
    consultantDetail: any;
};

const initialState: InitialState = {
    consultantDetail: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const consultantDetailReducer = (
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: InitialState = initialState,
    action: ConsultantDetailsAction,
) => {
    switch (action.type) {
        case ConsultantDetailsActionType.CONSULTANTDETAIL:
            return {
                ...state,
                consultantDetail: action.payload,
            };

        default:
            return state;
    }
};

export default consultantDetailReducer;

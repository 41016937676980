/* eslint-disable no-console */
import { AssignedBusinessRequestDataByConsultantAction } from '../../../../actions/vendor/business/assigned-business-request/assignedBusinessRequestAction';
import { UnitAssignedBusinessRequestByConsultantActionType } from '../../../../action-types';

type InitialState = {
    // [x: string]: { requestType: any; requestStatus: any; consultantEmail: any; businessRequestId: any; };
    unitAssignedBusinessRequestDatas: any;
};

const initialState: InitialState = {
    unitAssignedBusinessRequestDatas: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const assignedBusinessRequestReducer = (
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: InitialState = initialState,
    action: AssignedBusinessRequestDataByConsultantAction,
) => {
    switch (action.type) {
        case UnitAssignedBusinessRequestByConsultantActionType.UNITASSIGNEDUSINESSREQUEST:
            return {
                ...state,
                unitAssignedBusinessRequestDatas: action.payload,
            };

        default:
            return state;
    }
};

export default assignedBusinessRequestReducer;

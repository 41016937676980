/* eslint-disable import/no-unresolved */

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { throttle } from 'throttle-debounce';
import { loadState, setLocalState } from '../utils/helpers';
import rootReducer from './reducers';

const initialState = loadState() as any;

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
});

store.subscribe(
    throttle(500, async () => {
        setLocalState(store.getState());
    }),
);

export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;

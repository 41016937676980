// import Axios from '../../config/Axios';
import { Dispatch } from 'redux';
import { PersistActionType } from '../../action-types';
import { PersistAction } from './persistAction';
// import * as actionTypes from '../actionTypes';

export const persistData =
    (payload: boolean): any =>
    (dispatch: Dispatch<PersistAction>) => {
        dispatch({
            type: PersistActionType.PERSISTAUTH,
            payload,
        });
    };

export const testData = () => {};

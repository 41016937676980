/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-shadow */
import axios from 'axios';
import { handleErrorMessage, generateHeader, notifyError } from '../utils/helpers';
// import { getStoredToken, authRemoveAsyncData } from "../utils/helpers/auth";
import config from './config';
import store from '../store';
import { uiStartLoading, uiStopLoading } from '../store/actions/loading';
import { encrypt, decrypt } from '../utils/helpers/security';

const DocumentAxios = axios.create({
    baseURL: config.VERIFY_SERVER_URL,
    headers: {
        'X-Frame-Options': 'DENY',
        'X-Content-Type-Options': 'nosniff',
        'Cross-Origin-Opener-Policy': 'same-origin',
        'Content-Security-Policy': "frame-ancestors 'self' X-Frame-Options: DENY",
    },
});

const { dispatch } = store;

DocumentAxios.interceptors.request.use(
    (request) => {
        // const { isLoggedIn } = store.getState().userAuth.isLoggedIn;
        // const token = store.getState().userAuth?.tokenResponse?.token;

        if (!navigator.onLine) {
            throw {
                response: {
                    data: {
                        description: 'Please check your Internet Connection',
                    },
                },
            };
        }
        // const headers = generateHeader();
        // request.headers['x-token'] = headers['x-token'];
        // request.headers['Ocp-Apim-Subscription-Key'] = headers['Ocp-Apim-Subscription-Key'];
        // request.headers['Ocp-Apim-Trace'] = true;
        // request.headers.UTCTimestamp = headers.UTCTimestamp;
        // request.headers.Client_ID = headers.Client_ID;
        dispatch(uiStartLoading());

        // if (isLoggedIn) {
        //   request.headers["Authorization"] = `Bearer ${token}`;
        // } else {
        //   delete request.headers["Authorization"];
        // }

        return request;
    },
    (error) => {
        // Do something with request error
        dispatch(uiStopLoading());
        return Promise.reject(error);
    },
);

DocumentAxios.interceptors.response.use(
    (response) => {
        /* if (response.status !== 200 && response.data?.code !== '00') {
            throw {
                response: {
                    data: {
                        description: response?.data?.description,
                    },
                },
            };
        }
        if (response.status === 200 && response.data?.code !== '00') {
            dispatch(uiStopLoading());
            handleErrorMessage(response?.data?.description);
            return response;
        }
        dispatch(uiStopLoading());
        return response; */
        if (response.status !== 200) {
            dispatch(uiStopLoading());
            return null;
        }
        if (response.status === 200 && response.data?.code !== '00') {
            dispatch(uiStopLoading());
            notifyError(response.data?.description ?? response?.message);
            return null;
        }

        dispatch(uiStopLoading());
        return response;
    },
    async (error) => {
        if (error?.response?.data) {
            if (error?.response?.data?.description === 'access denied') {
                dispatch(uiStopLoading());
                handleErrorMessage(err?.response?.data?.description);
                localStorage.clear();
                window.location.replace('/login');
            }
        }
    },
);

export default DocumentAxios;

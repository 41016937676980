export enum AdminSettingsActionType {
    UPDATE_USER_PERMISSION = 'UPDATE_USER_PERMISSION',
}

interface UpdatePermissionAction {
    type: AdminSettingsActionType.UPDATE_USER_PERMISSION;
    payload: any;
}

export type AdminSettingsAction = UpdatePermissionAction;

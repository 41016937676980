/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-shadow */
// import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { removeAdminLocalToken } from '../store/actions/admin/auth/adminAuth';
import { removeLocalToken } from '../store/actions/vendor/auth/auth';
import { decodeJwt } from '../utils/helpers/security';
import { AdminActionType } from '../store/actions/admin/auth/adminAuthAction';
import {
    getLocalData,
    notifyError,
    removeLocalData,
    generateHeader,
    notifyInfo,
} from '../utils/helpers';
import config from './config';
import store from '../store';
import { uiStartLoading, uiStopLoading } from '../store/actions/loading';

// const navigate = useNavigate();

const StaffAxios = axios.create({
    baseURL: config.SERVER_URL,
    headers: {
        'X-Frame-Options': 'DENY',
        'X-Content-Type-Options': 'nosniff',
        'Cross-Origin-Opener-Policy': 'same-origin',
        'Content-Security-Policy': "frame-ancestors 'self' X-Frame-Options: DENY",
    },
});

const { dispatch } = store;

StaffAxios.interceptors.request.use(
    (request) => {
        if (!navigator.onLine) {
            throw {
                response: {
                    data: {
                        description: 'Please check your Internet Connection',
                    },
                },
            };
        }
        // const headers = generateHeader();
        // request.headers['x-token'] = headers['x-token'];
        // request.headers['Ocp-Apim-Subscription-Key'] = headers['Ocp-Apim-Subscription-Key'];
        // request.headers['Ocp-Apim-Trace'] = true;
        // request.headers.UTCTimestamp = headers.UTCTimestamp;
        // request.headers.Client_ID = headers.Client_ID;
        dispatch(uiStartLoading());

        const { adminAuth, consultantAuth } = store.getState();
        const { isLoggedIn, adminUserData } = adminAuth;
        const { consultantData, otpVerify } = consultantAuth;

        if (isLoggedIn && adminUserData) {
            request.headers.Authorization = `Bearer ${adminUserData?.accessToken}`;
        } else if (consultantData?.otpVerify) {
            request.headers.Authorization = `Bearer ${consultantData?.accessToken}`;
        }

        return request;
    },
    (error) => {
        dispatch(uiStopLoading());
        return Promise.reject(error);
    },
);

StaffAxios.interceptors.response.use(
    (response) => {
        // if (!(response.status === 200 && response.data?.code === '00')) {
        //     dispatch(uiStopLoading());
        //     notifyError(response.data?.description ?? response?.message);
        //     return null;
        // }
        if (response.status !== 200) {
            dispatch(uiStopLoading());
            return null;
        }
        if (response.status === 200 && response.data?.code !== '00') {
            dispatch(uiStopLoading());
            notifyError(response.data?.description ?? response?.message);
            return null;
        }

        dispatch(uiStopLoading());
        return response;
    },
    async (error) => {
        const routeCheck = window.location.pathname.split('/')[1];

        const clearStorage = () => {
            localStorage.clear();
            if (localStorage.getItem('appState-fcmb-sme-admin') === null) {
                dispatch({
                    type: AdminActionType.ADMIN_LOGOUT,
                    payload: null,
                });
            }
        };

        const logoutStaffWhenUnauthorized = async () => {
            try {
                const { adminAuth } = store.getState();
                const { adminUserData } = adminAuth;
                const user = jwt_decode(adminUserData?.accessToken);
                const response = await axios.post(`${config.SERVER_URL}users/staff-log-out`, {
                    email: user?.sub,
                });

                if (response) {
                    removeLocalData('appState-fcmb-sme-admin');
                    if (localStorage.getItem('appState-fcmb-sme-admin') === null) {
                        removeAdminLocalToken();
                    }
                    notifyInfo('Kindly Sign-in Again');
                }
            } catch (error) {
                removeLocalData('appState-fcmb-sme-admin');
                if (localStorage.getItem('appState-fcmb-sme-admin') === null) {
                    removeAdminLocalToken();
                }
            }
        };

        const logoutVendorWhenUnauthorized = async () => {
            const { accessToken } = store.getState().consultantAuth.consultantData ?? {};
            const { sub } = decodeJwt(accessToken);
            try {
                const response = await axios.post(
                    `${config.SERVER_URL}users/consultant-revoke-token`,
                    { email: sub },
                );
                if (response) {
                    await removeLocalData('appState-fcmb-sme-admin');
                    if (localStorage.getItem('appState-fcmb-sme-admin') === null) {
                        await removeLocalToken();
                    }
                    notifyInfo('Consultant successfully logout');
                }
            } catch (error) {
                removeLocalData('appState-fcmb-sme-admin');
                if (localStorage.getItem('appState-fcmb-sme-admin') === null) {
                    removeLocalToken();
                }
            }
        };

        if (error?.response?.status === 401 || error?.response?.status === 403) {
            dispatch(uiStopLoading());
            // notifyError(error?.response?.data?.description ?? error?.message);
            notifyError('Unauthorized Access');
            //
            if (routeCheck === 'admin') {
                await logoutStaffWhenUnauthorized();
                localStorage.clear();
                window.location.replace('/');
            } else if (routeCheck === 'vendor') {
                await logoutVendorWhenUnauthorized();
                localStorage.clear();
                window.location.replace('/');
            }
        } else if (error?.response?.status === 405) {
            dispatch(uiStopLoading());
            notifyError(error?.response?.statusText);
        } else if (error?.response?.status === 500) {
            dispatch(uiStopLoading());
            notifyError('Something went wrong');
        }

        dispatch(uiStopLoading());
    },
);

export const AxiosPrivate = axios.create({
    baseURL: config.SERVER_URL,
    withCredentials: true,
    headers: {
        'Cross-Origin-Opener-Policy': 'same-origin',
        'X-Frame-Options': 'SAMEORIGIN',
        'X-Content-Type-Options': 'nosniff',
        client_id: process.env.REACT_APP_CLIENT_ID,
        'Content-Type': 'application/json',
    },
});

export default StaffAxios;

import { combineReducers } from '@reduxjs/toolkit';
import uiReducer from './loading';
import adminAuthReducer from './admin/auth/adminUserAuth';
import consultantAuthReducer from './vendor/auth/auth';
import helperReducer from './helper/helper';
import persistReducer from './helper/persist';
import consultantDetailReducer from './vendor/business/consultantDetail';
import allBusinessRequestReducer from './vendor/business/all-business-requests-consultant/allBusinessRequest';
import assignedBusinessRequestReducer from './vendor/business/assigned-business-request/assignedBusinessRequest';
import notificationReducer from './notifications/notifications';
import userPermissionReducer from './admin/adminSettings/userPermission';

const reducers = combineReducers({
    loading: uiReducer,
    adminAuth: adminAuthReducer,
    adminSettings: userPermissionReducer,
    helper: helperReducer,
    consultantAuth: consultantAuthReducer,
    persist: persistReducer,
    consultantDetail: consultantDetailReducer,
    allBusinessRequest: allBusinessRequestReducer,
    assignedBusinessRequest: assignedBusinessRequestReducer,
    notification: notificationReducer,
});

export default reducers;

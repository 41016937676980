/* eslint-disable @typescript-eslint/default-param-last */
import * as actionTypes from '../actions/actionTypes';

const initalState: LoadingState = { isLoading: false };

const uiReducer = (state: LoadingState = initalState, action: Action) => {
    switch (action.type) {
        case actionTypes.UI_START_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.UI_STOP_LOADING:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
};

export default uiReducer;

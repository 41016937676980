/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable no-console */
// import { PayloadAction } from '@reduxjs/toolkit';
import { VerifyActionType, PopupActionType, PopupTwoActionType } from '../../action-types';
import { VerifyAction } from '../../actions/helper/helperAction';

type InitialState = {
    documentData: any;
    documentDataTwo: any;
};

const initialState: InitialState = {
    documentData: null,
    documentDataTwo: null,
};

const helperReducer = (state: InitialState = initialState, action: VerifyAction) => {
    switch (action.type) {
        case VerifyActionType.VERIFYID:
            return {
                ...state,
                documentData: { document: action.payload },
            };
        case PopupActionType.POPUP_STATE:
            return {
                ...state,
                documentData: { popup: action.payload },
            };

        case PopupTwoActionType.POPUP_STATE_TWO:
            return {
                ...state,
                documentDataTwo: { popupTwo: action.payload },
            };
        // updatePopupState

        default:
            return state;
    }
};

export default helperReducer;

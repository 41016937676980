export enum ConsultantActionType {
    CONSULTANT_REGISTER = 'CONSULTANT_REGISTER',
    CONSULTANT_LOGIN = 'CONSULTANT_LOGIN',
    CONSULTANT_REFRESH_TOKEN = 'CONSULTANT_REFRESH_TOKEN',
    CONSULTANT_REVOKE_TOKEN = 'CONSULTANT_REVOKE_TOKEN',
    CONSULTANT_VERIFY_LOGIN_OTP = 'CONSULTANT_VERIFY_LOGIN_OTP',
}

export enum VerifyActionType {
    VERIFYID = 'VERIFYID',
}

export enum PopupActionType {
    POPUP_STATE = 'POPUP_STATE',
}

export enum PopupTwoActionType {
    POPUP_STATE_TWO = 'POPUP_STATE_TWO',
}

export enum PersistActionType {
    PERSISTAUTH = 'PERSISTAUTH',
}

export enum ConsultantDetailsActionType {
    CONSULTANTDETAIL = 'CONSULTANTDETAIL',
}

export enum AllBusinessRequestByConsultantActionType {
    ALLBUSINESSREQUEST = 'ALLBUSINESSREQUEST',
}

export enum UnitAssignedBusinessRequestByConsultantActionType {
    UNITASSIGNEDUSINESSREQUEST = 'UNITASSIGNEDUSINESSREQUEST',
}

export enum GetNotificationsActionType {
    GETNOTIFICATIONS = 'GETNOTIFICATIONS',
}

export enum AdminAuthDetailsActionTypes {
    ADMINREMEMBERME = 'ADMINREMEMBERME',
}

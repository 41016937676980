/* eslint-disable no-nested-ternary */
/* eslint-disable no-async-promise-executor */
// all-assigned-business-requests-by-consultant-id?ConsultantId=1
// AllBusinessRequestsByConsultantAction

// import Axios from '../../config/Axios';
import { Dispatch } from 'redux';
import Axios from '../../../../../config/Axios';
import { AllBusinessRequestByConsultantActionType } from '../../../../action-types';
import store from '../../../../index';
import { uiStopLoading } from '../../../loading';
import { AllBusinessRequestsByConsultantAction } from './allBusinessRequestAction';
// import * as actionTypes from '../actionTypes';

/* const getErrorMessage = (data: any): string => {
    const message = Object.values(data)[0] as string;
    return message;
}; */

const { dispatch: customDispatch } = store;

// eslint-disable-next-line import/prefer-default-export
export const getAllBusinessRequest =
    (payload: any): any =>
    async (dispatch: Dispatch<AllBusinessRequestsByConsultantAction>): Promise<any> => {
        const controller = new AbortController();
        return new Promise(async (resolve, reject) => {
            try {
                const response = await Axios.post(
                    `get-all-assigned-business-requests-by-consultant-id`,
                    payload,
                    {
                        signal: controller.signal,
                    },
                );
                if (!response) {
                    resolve({
                        error: true,
                        // message: response?.data?.description || 'Unable to retrieve data',
                    });
                } else {
                    dispatch({
                        type: AllBusinessRequestByConsultantActionType.ALLBUSINESSREQUEST,
                        payload: response?.data,
                    });
                    resolve({
                        error: false,
                        message: 'Data retrieved',
                        data: payload?.requestStatus || null,
                    });
                }
            } catch (error: any) {
                customDispatch(uiStopLoading());
                const e: any = {
                    error: true,
                    /* message:
                        error?.response?.status === 500
                            ? 'Internal Sever error'
                            : error?.response?.status === 400
                            ? error?.response?.data?.title ??
                              getErrorMessage(error?.response?.data?.errors)
                            : 'Error occured, Please check your network', */
                };
                reject(e);
            }
        });
    };

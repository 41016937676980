/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable no-console */
// import { PayloadAction } from '@reduxjs/toolkit';
import { GetNotificationsActionType } from 'store/action-types';
import { GetAllNotificationsAction } from 'store/actions/notifications/notificationAction';
// import { saveDataLocally } from '../../../../utils/helpers';

type InitialState = {
    notificationList: any;
};

const initialState: InitialState = {
    notificationList: null,
};

const notificationReducer = (
    state: InitialState = initialState,
    action: GetAllNotificationsAction,
) => {
    switch (action.type) {
        case GetNotificationsActionType.GETNOTIFICATIONS:
            return {
                ...state,
                notificationList: action.payload,
            };

        default:
            return state;
    }
};

export default notificationReducer;

/* eslint-disable no-console */
import { AdminSettingsActionType } from '../../../actions/admin/adminSettings/adminSettingsAction';

type InitialState = {
    userPermissionToUpdate: any;
};

const initialState: InitialState = {
    userPermissionToUpdate: undefined,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const userPermissionReducer = (state: InitialState = initialState, action: any) => {
    switch (action.type) {
        case AdminSettingsActionType.UPDATE_USER_PERMISSION:
            return {
                ...state,
                userPermissionToUpdate: action.payload,
            };
        default:
            return state;
    }
};

export default userPermissionReducer;

/* eslint-disable no-console */
import { AllBusinessRequestByConsultantActionType } from '../../../../action-types';
import { AllBusinessRequestsByConsultantAction } from '../../../../actions/vendor/business/all-business-requests-consultant/allBusinessRequestAction';

type InitialState = {
    allBusinessRequestDatas: any;
};

const initialState: InitialState = {
    allBusinessRequestDatas: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const allBusinessRequestReducer = (
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: InitialState = initialState,
    action: AllBusinessRequestsByConsultantAction,
) => {
    switch (action.type) {
        case AllBusinessRequestByConsultantActionType.ALLBUSINESSREQUEST:
            return {
                ...state,
                allBusinessRequestDatas: action.payload,
            };

        default:
            return state;
    }
};

export default allBusinessRequestReducer;

const IDProfileServices = [
    { label: 'Training and Mentorship', value: 'training and mentorship', code: 'D1' },
    { label: 'Advisory Service', value: 'advisory service', code: 'D2' },
    {
        label: 'Access to digital business management tools',
        value: 'access to digital business management tools',
        code: 'D3',
    },
    {
        label: 'Access to new market & Networking',
        value: 'access to new market & networking',
        code: 'D4',
    },
];

const colourOptions = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9' },
    { value: 'blue', label: 'Blue', color: '#0052CC', disabled: true },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630' },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
];

const countries = [
    { country: 'SYRIA', country_code: 'SY', country_phone_code: '+963' },
    { country: 'Afghanistan', country_code: 'AF', country_phone_code: '+93' },
    { country: 'Belarus', country_code: 'BY', country_phone_code: '+375' },
    {
        country: 'Democratic Republic of Congo',
        country_code: 'CD',
        country_phone_code: '+242',
    },
    { country: 'Iran', country_code: 'IR', country_phone_code: '+98' },
    { country: 'Burma', country_code: 'MM', country_phone_code: '+95' },
    { country: 'LEBANON', country_code: 'LB', country_phone_code: '+961' },
    { country: 'ANDORA', country_code: 'AD', country_phone_code: '+376' },
    {
        country: 'UNITED ARAB EMIRATES',
        country_code: 'AE',
        country_phone_code: '+971',
    },
    { country: 'ANGOLA', country_code: 'AO', country_phone_code: '+244' },
    { country: 'ARGENTINA', country_code: 'AR', country_phone_code: '+54' },
    { country: 'AUSTRIA', country_code: 'AT', country_phone_code: '+43' },
    { country: 'AUSTRALIA', country_code: 'AU', country_phone_code: '+61' },
    { country: 'BELGIUM', country_code: 'BE', country_phone_code: '+32' },
    {
        country: 'BURKINA FASO',
        country_code: 'BF',
        country_phone_code: '+226',
    },
    { country: 'BULGARIA', country_code: 'BG', country_phone_code: '+359' },
    { country: 'BURUNDI', country_code: 'BI', country_phone_code: '+257' },
    {
        country: 'BENIN REPUBLIC',
        country_code: 'BJ',
        country_phone_code: '+229',
    },
    { country: 'BRAZIL', country_code: 'BR', country_phone_code: '+55' },
    { country: 'BOTSWANA', country_code: 'BW', country_phone_code: '+267' },
    { country: 'CANADA', country_code: 'CA', country_phone_code: '+1' },
    {
        country: 'CENTRAL AFRICA REPUBLIC',
        country_code: 'CF',
        country_phone_code: '+236',
    },
    { country: 'CONGO', country_code: 'CG', country_phone_code: '+242' },
    { country: 'SWITZERLAND', country_code: 'CH', country_phone_code: '+41' },
    {
        country: "COTE D'IVOIRE",
        country_code: 'CI',
        country_phone_code: '+225',
    },
    { country: 'CHILE', country_code: 'CL', country_phone_code: '+56' },
    { country: 'CAMEROON', country_code: 'CM', country_phone_code: '+237' },
    { country: 'CHINA', country_code: 'CN', country_phone_code: '+86' },
    { country: 'COLOMBIA', country_code: 'CO', country_phone_code: '+57' },
    { country: 'COSTA RICA', country_code: 'CR', country_phone_code: '+506' },
    { country: 'CUBA', country_code: 'CU', country_phone_code: '+53' },
    { country: 'CAPE VERDE', country_code: 'CV', country_phone_code: '+238' },
    { country: 'CYPRUS', country_code: 'CY', country_phone_code: '+537' },
    {
        country: 'CZECH REPUBLIC',
        country_code: 'CZ',
        country_phone_code: '+420',
    },
    { country: 'GERMANY', country_code: 'DE', country_phone_code: '+49' },
    { country: 'DENMARK', country_code: 'DK', country_phone_code: '+45' },
    { country: 'ALGERIA', country_code: 'DZ', country_phone_code: '+213' },
    { country: 'ECUADOR', country_code: 'EC', country_phone_code: '+593' },
    {
        country: 'ECOWAS COUNTRY CODE',
        country_code: 'ECO',
        country_phone_code: '',
    },
    {
        country: 'ECONOMIC COMMUNITY OF WEST AFRICAN STATES',
        country_code: 'ECOWA',
    },
    { country: 'EGYPT', country_code: 'EG', country_phone_code: '+20' },
    { country: 'SPAIN', country_code: 'ES', country_phone_code: '+34' },
    { country: 'ETHIOPIA', country_code: 'ET', country_phone_code: '+251' },
    {
        country: 'EUROPEAN UNION COUNTRIES',
        country_code: 'EU',
        country_phone_code: '',
    },
    { country: 'FINLAND', country_code: 'FI', country_phone_code: '+358' },
    { country: 'FRANCE', country_code: 'FR', country_phone_code: '+33' },
    { country: 'GABON', country_code: 'GA', country_phone_code: '+241' },
    {
        country: 'UNITED KINGDOM',
        country_code: 'GB',
        country_phone_code: '+44',
    },
    { country: 'GEORGIA', country_code: 'GE', country_phone_code: '+995' },
    { country: 'GHANA', country_code: 'GH', country_phone_code: '+233' },
    { country: 'GAMBIA', country_code: 'GM', country_phone_code: '+220' },
    { country: 'GUINEA', country_code: 'GN', country_phone_code: '+224' },
    {
        country: 'EQUITORIAL GUINEA',
        country_code: 'GQ',
        country_phone_code: '+240',
    },
    { country: 'GREECE', country_code: 'GR', country_phone_code: '+30' },
    {
        country: 'GUINEA BISSAU',
        country_code: 'GW',
        country_phone_code: '+245',
    },
    { country: 'HONG KONG', country_code: 'HK', country_phone_code: '+852' },
    { country: 'HONDURAS', country_code: 'HN', country_phone_code: '+504' },
    { country: 'CROATIA', country_code: 'HR', country_phone_code: '+385' },
    { country: 'HAITI', country_code: 'HT', country_phone_code: '+509' },
    { country: 'HUNGARY', country_code: 'HU', country_phone_code: '+36' },
    { country: 'IDA', country_code: 'IA', country_phone_code: '' },
    { country: 'IFC', country_code: 'IC', country_phone_code: '' },
    { country: 'INDONESIA', country_code: 'ID', country_phone_code: '+62' },
    { country: 'IRELAND', country_code: 'IE', country_phone_code: '+353' },
    { country: 'ISRAEL', country_code: 'IL', country_phone_code: '+972' },
    { country: 'INDIA', country_code: 'IN', country_phone_code: '+246' },
    { country: 'IRAQ', country_code: 'IQ', country_phone_code: '+964' },
    { country: 'ICELAND', country_code: 'IS', country_phone_code: '+354' },
    { country: 'ITALY', country_code: 'IT', country_phone_code: '+39' },
    { country: 'JAMAICA', country_code: 'JM', country_phone_code: '+1876' },
    { country: 'JORDAN', country_code: 'JQ', country_phone_code: '+962' },
    { country: 'JAPAN', country_code: 'JP', country_phone_code: '+81' },
    { country: 'KENYA', country_code: 'KE', country_phone_code: '+254' },
    { country: 'NORTH KOREA', country_code: 'KP', country_phone_code: '+850' },
    { country: 'SOUTH KOREA', country_code: 'KR', country_phone_code: '+82' },
    { country: 'LEBANON', country_code: 'LE', country_phone_code: '+961' },
    { country: 'LIBERIA', country_code: 'LR', country_phone_code: '+231' },
    { country: 'LESOTHO', country_code: 'LS', country_phone_code: '+266' },
    { country: 'MOROCCO', country_code: 'MA', country_phone_code: '+212' },
    { country: 'MADAGASCAR', country_code: 'MG', country_phone_code: '+261' },
    { country: 'MALI', country_code: 'ML', country_phone_code: '+223' },
    { country: 'MAURITANIA', country_code: 'MR', country_phone_code: '+222' },
    { country: 'MAURITIUS', country_code: 'MU', country_phone_code: '+230' },
    { country: 'MALAWI', country_code: 'MW', country_phone_code: '+265' },
    { country: 'MEXICO', country_code: 'MX', country_phone_code: '+52' },
    { country: 'MALAYSIA', country_code: 'MY', country_phone_code: '+60' },
    { country: 'MOZAMBIQUE', country_code: 'MZ', country_phone_code: '+258' },
    { country: 'NAMIBIA', country_code: 'NA', country_phone_code: '+264' },
    { country: 'NIGER', country_code: 'NE', country_phone_code: '+227' },
    { country: 'NIGERIA', country_code: 'NG', country_phone_code: '+234' },
    { country: 'NETHERLANDS', country_code: 'NL', country_phone_code: '+31' },
    { country: 'NORWAY', country_code: 'NO', country_phone_code: '+47' },
    { country: 'NEPAL', country_code: 'NP', country_phone_code: '+977' },
    { country: 'NEW ZEALAND', country_code: 'NZ', country_phone_code: '+64' },
    { country: 'PERU', country_code: 'PE', country_phone_code: '+51' },
    { country: 'PHILIPINES', country_code: 'PH', country_phone_code: '+63' },
    { country: 'PAKISTAN', country_code: 'PK', country_phone_code: '+92' },
    { country: 'POLAND', country_code: 'PL', country_phone_code: '+48' },
    {
        country: 'PUERTO RICO',
        country_code: 'PR',
        country_phone_code: '+1787',
    },
    { country: 'PORTUGAL', country_code: 'PT', country_phone_code: '+351' },
    { country: 'PARAGUAY', country_code: 'PY', country_phone_code: '+595' },
    { country: 'ROMANIA', country_code: 'RO', country_phone_code: '+40' },
    { country: 'RWANDA', country_code: 'RW', country_phone_code: '+250' },
    {
        country: 'SAUDI ARABIA',
        country_code: 'SC',
        country_phone_code: '+966',
    },
    { country: 'SUDAN', country_code: 'SD', country_phone_code: '+249' },
    { country: 'SWEDEN', country_code: 'SE', country_phone_code: '+46' },
    { country: 'SINGAPORE', country_code: 'SG', country_phone_code: '+65' },
    { country: 'SLOVENIA', country_code: 'SI', country_phone_code: '+386' },
    {
        country: 'SIERRA LEONE',
        country_code: 'SL',
        country_phone_code: '+232',
    },
    { country: 'SENEGAL', country_code: 'SN', country_phone_code: '+221' },
    { country: 'SOMALIA', country_code: 'SO', country_phone_code: '+252' },
    { country: 'SWAZILAND', country_code: 'SZ', country_phone_code: '+268' },
    { country: 'CHAD', country_code: 'TD', country_phone_code: '+235' },
    { country: 'TOGO', country_code: 'TG', country_phone_code: '+228' },
    { country: 'THAILAND', country_code: 'TH', country_phone_code: '+66' },
    { country: 'TUNISIA', country_code: 'TN', country_phone_code: '+216' },
    { country: 'TURKEY', country_code: 'TR', country_phone_code: '+90' },
    { country: 'TANZANIA', country_code: 'TZ', country_phone_code: '+255' },
    { country: 'UKRAINE', country_code: 'UA', country_phone_code: '+380' },
    { country: 'UGANDA', country_code: 'UG', country_phone_code: '+256' },
    {
        country: 'UNITED STATE OF AMERICA',
        country_code: 'US',
        country_phone_code: '+1',
    },
    { country: 'URUGUAY', country_code: 'UY', country_phone_code: '+598' },
    { country: 'VENEZUELA', country_code: 'VE', country_phone_code: '+58' },
    { country: 'YUGOSLAVIA', country_code: 'YU', country_phone_code: '+38' },
    { country: 'SOUTH AFRICA', country_code: 'ZA', country_phone_code: '+27' },
    { country: 'ZAMBIA', country_code: 'ZM', country_phone_code: '+260' },
    { country: 'ZIMBABWE', country_code: 'ZW', country_phone_code: '+263' },
    { country: 'ALBANIA', country_code: 'AL', country_phone_code: '+355' },
    { country: 'ARMENIA', country_code: 'AM', country_phone_code: '+374' },
    {
        country: 'BOSNIA AND HERZEGOVINA',
        country_code: 'BA',
        country_phone_code: '+387',
    },
    { country: 'BAHRAIN', country_code: 'BH', country_phone_code: '+973' },
    {
        country: 'SAINT BARTHELEMY',
        country_code: 'BL',
        country_phone_code: '+590',
    },
    { country: 'BHUTAN', country_code: 'BT', country_phone_code: '+975' },
    { country: 'BOUVET ISLAND', country_code: 'BV', country_phone_code: '' },
    {
        country: 'COOK ISLANDS',
        country_code: 'CK',
        country_phone_code: '+682',
    },
    { country: 'CURACAO', country_code: 'CW', country_phone_code: '+599' },
    { country: 'DJIBOUTI', country_code: 'DJ', country_phone_code: '+253' },
    {
        country: 'DOMINICAN REPUBLIC',
        country_code: 'DO',
        country_phone_code: '+1809',
    },
    { country: 'ESTONIA', country_code: 'EE', country_phone_code: '+372' },
    {
        country: 'WESTERN SAHARA',
        country_code: 'EH',
        country_phone_code: '+212',
    },
    { country: 'ERITREA', country_code: 'ER', country_phone_code: '+291' },
    { country: 'FIJI', country_code: 'FJ', country_phone_code: '+679' },
    {
        country: 'FALKLAND ISLANDS (MALVINAS)',
        country_code: 'FK',
        country_phone_code: '+500',
    },
    {
        country: 'FAROE ISLANDS',
        country_code: 'FO',
        country_phone_code: '+298',
    },
    {
        country: 'FRENCH GUIANA',
        country_code: 'GF',
        country_phone_code: '+594',
    },
    { country: 'GIBRALTAR', country_code: 'GI', country_phone_code: '+350' },
    { country: 'GREENLAND', country_code: 'GL', country_phone_code: '+299' },
    { country: 'GUATEMALA', country_code: 'GT', country_phone_code: '+502' },
    { country: 'JERSEY', country_code: 'JE', country_phone_code: '+44' },
    { country: 'KYRGYZSTAN', country_code: 'KG', country_phone_code: '+996' },
    {
        country: 'SAINT KITTS AND NEVIS',
        country_code: 'KN',
        country_phone_code: '+1',
    },
    { country: 'KUWAIT', country_code: 'KW', country_phone_code: '+965' },
    { country: 'MONACO', country_code: 'MC', country_phone_code: '+377' },
    {
        country: 'SAINT MARTIN (FRENCH PART)',
        country_code: 'MF',
        country_phone_code: '+590',
    },
    { country: 'MALTA', country_code: 'MT', country_phone_code: '+356' },
    { country: 'OMAN', country_code: 'ON', country_phone_code: '+968' },
    {
        country: 'FRENCH POLYNESIA',
        country_code: 'PF',
        country_phone_code: '+689',
    },
    {
        country: 'SAINT PIERRE AND MIQUELON',
        country_code: 'PM',
        country_phone_code: '+508',
    },
    { country: 'QATAR', country_code: 'QA', country_phone_code: '+974' },
    { country: 'SERBIA', country_code: 'RS', country_phone_code: '+381' },
    {
        country: 'SAO TOME AND PRINCIPE',
        country_code: 'ST',
        country_phone_code: '+239',
    },
    {
        country: 'SINT MAARTEN (DUTCH PART)',
        country_code: 'SX',
        country_phone_code: '+721',
    },
    {
        country: 'TURKS AND CAICOS ISLANDS',
        country_code: 'TC',
        country_phone_code: '+1649',
    },
    {
        country: 'FRENCH SOUTHERN TERRITORIES',
        country_code: 'TF',
        country_phone_code: '',
    },
    { country: 'TONGA', country_code: 'TO', country_phone_code: '+676' },
    { country: 'TUVALU', country_code: 'TV', country_phone_code: '+688' },
    {
        country: 'SAINT VINCENT AND THE GRENADINES',
        country_code: 'VC',
        country_phone_code: '+1',
    },
    {
        country: 'VIRGIN ISLANDS (BRITISH)',
        country_code: 'VG',
        country_phone_code: '+1340',
    },
    {
        country: 'WALLIS AND FUTUNA',
        country_code: 'WF',
        country_phone_code: '+681',
    },
    { country: 'YEMEN', country_code: 'YE', country_phone_code: '+967' },
    { country: 'BELIZE', country_code: 'BZ', country_phone_code: '+501' },
    {
        country: 'ALAND ISLANDS',
        country_code: 'AX',
        country_phone_code: '+358',
    },
    { country: 'BANGLADESH', country_code: 'BD', country_phone_code: '+880' },
    {
        country: 'BOLIVIA (PLURINATIONAL STATE OF)',
        country_code: 'BO',
        country_phone_code: '+591',
    },
    { country: 'GUADELOUPE', country_code: 'GP', country_phone_code: '+590' },
    {
        country: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
        country_code: 'GS',
        country_phone_code: '+500',
    },
    { country: 'GUAM', country_code: 'GU', country_phone_code: '+1671' },
    { country: 'GUYANA', country_code: 'GY', country_phone_code: '+595' },
    {
        country: 'HEARD ISLAND AND MCDONALD ISLANDS',
        country_code: 'HM',
        country_phone_code: '',
    },
    { country: 'DOMINICA', country_code: 'DM', country_phone_code: '+1767' },
    {
        country: 'MICRONESIA (FEDERATED STATES OF)',
        country_code: 'FM',
        country_phone_code: '+691',
    },
    { country: 'GRENADA', country_code: 'GD', country_phone_code: '+1473' },
    { country: 'ISLE OF MAN', country_code: 'IM', country_phone_code: '+44' },
    {
        country: 'BRITISH INDIAN OCEAN TERRITORY',
        country_code: 'IO',
        country_phone_code: '+246',
    },
    { country: 'CAMBODIA', country_code: 'KH', country_phone_code: '+855' },
    { country: 'KIRIBATI', country_code: 'KI', country_phone_code: '+686' },
    { country: 'COMOROS', country_code: 'KM', country_phone_code: '+269' },
    {
        country: 'CAYMAN ISLANDS',
        country_code: 'KY',
        country_phone_code: '+345',
    },
    {
        country: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
        country_code: 'LA',
        country_phone_code: '+856',
    },
    { country: 'SAINT LUCIA', country_code: 'LC', country_phone_code: '+1' },
    {
        country: 'LIECHTENSTEIN',
        country_code: 'LI',
        country_phone_code: '+423',
    },
    { country: 'SRI LANKA', country_code: 'LK', country_phone_code: '+94' },
    { country: 'LITHUANIA', country_code: 'LT', country_phone_code: '+370' },
    { country: 'LUXEMBOURG', country_code: 'LU', country_phone_code: '+352' },
    { country: 'LATVIA', country_code: 'LV', country_phone_code: '+371' },
    {
        country: 'MOLDOVA (REPUBLIC OF)',
        country_code: 'MD',
        country_phone_code: '+373',
    },
    { country: 'MONTENEGRO', country_code: 'ME', country_phone_code: '+382' },
    {
        country: 'MARSHALL ISLANDS',
        country_code: 'MH',
        country_phone_code: '+692',
    },
    { country: 'NOT APPLICABLE', country_code: 'N/A', country_phone_code: '' },
];

const states = [
    {
        stateCode: 'LG',
        state: 'lagos',
        lgas: [
            {
                lgaCode: 'LAG',
                lga: 'LAGOS ISLAND',
            },
            {
                lgaCode: 'APP',
                lga: 'APAPA',
            },
            {
                lgaCode: 'IKJ',
                lga: 'IKEJA',
            },
            {
                lgaCode: 'VIL',
                lga: 'VICTORIA ISLAND',
            },
            {
                lgaCode: 'OJO',
                lga: 'OJO',
            },
            {
                lgaCode: 'IKY',
                lga: 'IKOYI',
            },
            {
                lgaCode: 'ALM',
                lga: 'ALIMOSHO',
            },
            {
                lgaCode: 'AJA',
                lga: 'AJAH',
            },
            {
                lgaCode: 'OSS',
                lga: 'OSHODIISOLO',
            },
            {
                lgaCode: 'IKD',
                lga: 'IKORODU',
            },
            {
                lgaCode: 'MUS',
                lga: 'MUSHIN',
            },
            {
                lgaCode: 'SLR',
                lga: 'SURULERE',
            },
            {
                lgaCode: 'ETS',
                lga: 'ETIOSA',
            },
            {
                lgaCode: 'ISL',
                lga: 'ISOLO',
            },
            {
                lgaCode: 'KSF',
                lga: 'KOSOFE',
            },
            {
                lgaCode: 'AGG',
                lga: 'AGEGE',
            },
            {
                lgaCode: 'AJR',
                lga: 'AJEROMI/IFELODUN',
            },
            {
                lgaCode: 'SMK',
                lga: 'SHOMOLU',
            },
            {
                lgaCode: 'AMW',
                lga: 'AMUWO ODOFIN',
            },
            {
                lgaCode: 'LAG2',
                lga: 'LAGOS MAINLAND',
            },
        ],
        branches: [
            {
                branch: 'HEAD OFFICE BRANCH',
                sol: '001',
            },
            {
                branch: 'APAPA I BRANCH',
                sol: '006',
            },
            {
                branch: 'OBA AKRAN I BRANCH',
                sol: '007',
            },
            {
                branch: 'IDUMAGBO BRANCH',
                sol: '010',
            },
            {
                branch: 'ADEOLA ODEKU BRANCH',
                sol: '023',
            },
            {
                branch: 'ALABA I BRANCH',
                sol: '026',
            },
            {
                branch: 'AWOLOWO I BRANCH',
                sol: '027',
            },
            {
                branch: 'ALAGBADO BRANCH',
                sol: '030',
            },
            {
                branch: 'ALLEN I BRANCH',
                sol: '031',
            },
            {
                branch: 'OKE-ARIN BRANCH',
                sol: '032',
            },
            {
                branch: 'AJAH BRANCH',
                sol: '034',
            },
            {
                branch: 'IDUMOTA',
                sol: '037',
            },
            {
                branch: 'BROAD STREET BRANCH',
                sol: '039',
            },
            {
                branch: 'AIRPORT RD BRANCH',
                sol: '051',
            },
            {
                branch: 'APAPA II BRANCH',
                sol: '056',
            },
            {
                branch: 'IKORODU BRANCH',
                sol: '061',
            },
            {
                branch: 'MOTORWAYS BRANCH',
                sol: '065',
            },
            {
                branch: 'OJO BRANCH',
                sol: '066',
            },
            {
                branch: 'SANUSI FAFUNWA BRANCH',
                sol: '069',
            },
            {
                branch: ' MUSHIN BRANCH',
                sol: '075',
            },
            {
                branch: 'AKOWONJO BRANCH',
                sol: '076',
            },
            {
                branch: 'ADENIRAN OGUNSANYA BRANCH',
                sol: '079',
            },
            {
                branch: 'OGBA BRANCH',
                sol: '081',
            },
            {
                branch: 'YABA BRANCH',
                sol: '082',
            },
            {
                branch: 'ONIRU BRANCH',
                sol: '083',
            },
            {
                branch: 'MATORI BRANCH',
                sol: '087',
            },
            {
                branch: 'OKOTA BRANCH',
                sol: '091',
            },
            {
                branch: 'LEKKI  BRANCH',
                sol: '095',
            },
            {
                branch: 'KETU BRANCH',
                sol: '107',
            },
            {
                branch: 'ADEOLA HOPEWELL BRANCH',
                sol: '110',
            },
            {
                branch: 'AGEGE BRANCH',
                sol: '114',
            },
            {
                branch: 'ORILE COKER BRANCH',
                sol: '118',
            },
            {
                branch: 'MACARTHY BRANCH',
                sol: '119',
            },
            {
                branch: 'IDIMU BRANCH',
                sol: '129',
            },
            {
                branch: 'DETOKUNBO ADEMOLA BRANCH',
                sol: '132',
            },
            {
                branch: 'MARINA BRANCH',
                sol: '135',
            },
            {
                branch: 'IKEJA GRA BRANCH',
                sol: '137',
            },
            {
                branch: 'WHARF',
                sol: '142',
            },
            {
                branch: 'KINGSWAY ROAD BRANCH',
                sol: '143',
            },
            {
                branch: 'ONIPAN BRANCH',
                sol: '145',
            },
            {
                branch: 'DAVIES STREET BRANCH',
                sol: '146',
            },
            {
                branch: 'TRADE FAIR II BRANCH',
                sol: '147',
            },
            {
                branch: 'OSOLO WAY BRANCH',
                sol: '152',
            },
            {
                branch: 'RANDLE RD BRANCH',
                sol: '154',
            },
            {
                branch: 'ASPAMDA II BRANCH',
                sol: '155',
            },
            {
                branch: 'MOBOLAJI BANK ANTHONY BRANCH',
                sol: '157',
            },
            {
                branch: 'IKEJA LOCAL AIRPORT BRANCH',
                sol: '158',
            },
            {
                branch: 'OROYINYIN BRANCH',
                sol: '161',
            },
            {
                branch: 'ALABA II BRANCH',
                sol: '163',
            },
            {
                branch: 'OREGUN BRANCH',
                sol: '164',
            },
            {
                branch: 'OSHODI BRANCH',
                sol: '165',
            },
            {
                branch: 'JOSEPH STREET BRANCH',
                sol: '167',
            },
            {
                branch: 'IDDO BRANCH',
                sol: '169',
            },
            {
                branch: 'FESTAC BRANCH',
                sol: '174',
            },
            {
                branch: 'LEKKI CHEVRON BRANCH',
                sol: '175',
            },
            {
                branch: 'OGUDU BRANCH',
                sol: '176',
            },
            {
                branch: 'ILUPEJU BRANCH',
                sol: '181',
            },
            {
                branch: 'LEKKI ADMIRALTY WAY BRANCH',
                sol: '280',
            },
            {
                branch: 'AMUWO-ODOFIN BRANCH',
                sol: '282',
            },
            {
                branch: 'GBAGADA BRANCH',
                sol: '283',
            },
        ],
        region: '70j',
    },
    {
        stateCode: 'FC',
        state: 'federal capital territory',
        lgas: [
            {
                lgaCode: 'ABJ',
                lga: 'ABUJA',
            },
            {
                lgaCode: 'GAR',
                lga: 'GARKI',
            },
            {
                lgaCode: 'BWA',
                lga: 'BWARI',
            },
            {
                lgaCode: 'GWG',
                lga: 'GWAGWALADA',
            },
            {
                lgaCode: 'KUJ',
                lga: 'KUJE',
            },
        ],
        branches: [
            {
                branch: 'ABUJA FIRST CITY PLAZA BRANCH',
                sol: '002',
            },
            {
                branch: 'ABUJA AREA 8 BRANCH',
                sol: '044',
            },
            {
                branch: 'ABUJA FEDSEC PHASE III BRANCH',
                sol: '073',
            },
            {
                branch: 'ABUJA WUSE II BRANCH',
                sol: '111',
            },
            {
                branch: 'ABUJA BANEX PLAZA BRANCH',
                sol: '138',
            },
            {
                branch: 'ASOKORO BRANCH',
                sol: '182',
            },
            {
                branch: 'ABUJA AREA 7 BRANCH',
                sol: '185',
            },
            {
                branch: 'ABUJA  ZONE 4 BRANCH',
                sol: '189',
            },
            {
                branch: 'ABUJA GARKI II BRANCH',
                sol: '190',
            },
            {
                branch: 'ABUJA PORTHACOURT CRESCENT BRANCH',
                sol: '205',
            },
            {
                branch: 'JABI BRANCH',
                sol: '206',
            },
            {
                branch: 'MAITAMA MEDITERRANEAN BRANCH',
                sol: '207',
            },
            {
                branch: 'ABUJA BWARI BRANCH',
                sol: '218',
            },
            {
                branch: 'ABUJA AMINU KANO CRESCENT BRANCH',
                sol: '220',
            },
            {
                branch: 'ABUJA GUDU BRANCH',
                sol: '222',
            },
            {
                branch: 'ABUJA NASS I BRANCH',
                sol: '223',
            },
            {
                branch: 'GWAGWALADA BRANCH',
                sol: '225',
            },
            {
                branch: 'ABUJA KUJE BRANCH',
                sol: '226',
            },
            {
                branch: 'ABUJA CREST PLAZA BRANCH',
                sol: '227',
            },
            {
                branch: 'ABUJA KUBWA BRANCH',
                sol: '231',
            },
            {
                branch: 'ABUJA GANA STREET BRANCH',
                sol: '233',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'RV',
        state: 'rivers',
        lgas: [
            {
                lgaCode: 'PHC',
                lga: 'PORTHARCOURT',
            },
            {
                lgaCode: 'OYIG',
                lga: 'OYIGBO',
            },
            {
                lgaCode: 'OBIA',
                lga: 'OBIA/AKPOR',
            },
            {
                lgaCode: 'KHA',
                lga: 'KHANA',
            },
        ],
        branches: [
            {
                branch: 'PH MAIN BRANCH',
                sol: '003',
            },
            {
                branch: 'GARRISON BRANCH',
                sol: '043',
            },
            {
                branch: 'OLU OBASANJO BRANCH',
                sol: '049',
            },
            {
                branch: 'IKWERRE I BRANCH',
                sol: '064',
            },
            {
                branch: 'OYIGBO BRANCH',
                sol: '108',
            },
            {
                branch: 'ABULOMA BRANCH',
                sol: '109',
            },
            {
                branch: 'AGGREY BRANCH',
                sol: '126',
            },
            {
                branch: 'TRANS AMADI II BRANCH',
                sol: '134',
            },
            {
                branch: 'IKWERRE II BRANCH',
                sol: '239',
            },
            {
                branch: 'AZIKIWE BRANCH',
                sol: '252',
            },
            {
                branch: 'RUMUOMASI BRANCH',
                sol: '253',
            },
            {
                branch: 'PH ABA ROAD 2',
                sol: '256',
            },
            {
                branch: 'BORI BRANCH',
                sol: '261',
            },
            {
                branch: 'TRANS AMADI III BRANCH',
                sol: '268',
            },
            {
                branch: 'RUMUOKORO BRANCH',
                sol: '275',
            },
        ],
        region: '90j',
    },
    {
        stateCode: 'KN',
        state: 'kano',
        lgas: [
            {
                lgaCode: 'FAG',
                lga: 'FAGGE',
            },
        ],
        branches: [
            {
                branch: 'KANO MAIN BRANCH',
                sol: '004',
            },
            {
                branch: 'KANO BELLO ROAD BRANCH',
                sol: '055',
            },
            {
                branch: 'KANO MM WAY 1 BRANCH',
                sol: '183',
            },
            {
                branch: 'KANO 40 MM WAY  BRANCH',
                sol: '199',
            },
            {
                branch: 'KANO IBRAHIM TAIWO BRANCH',
                sol: '200',
            },
            {
                branch: 'KANO BOMPAI BRANCH',
                sol: '213',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'DL',
        state: 'delta',
        lgas: [
            {
                lgaCode: 'WAR3',
                lga: 'WARRI SOUTH',
            },
            {
                lgaCode: 'OSM',
                lga: 'OSHIMILI',
            },
            {
                lgaCode: 'UVW',
                lga: 'UVWIE',
            },
            {
                lgaCode: 'UDH1',
                lga: 'UGHELLI NORTH',
            },
        ],
        branches: [
            {
                branch: 'WARRI  I BRANCH',
                sol: '008',
            },
            {
                branch: 'ASABA I BRANCH',
                sol: '054',
            },
            {
                branch: 'EFFURUN BRANCH',
                sol: '072',
            },
            {
                branch: 'WARRI II (AIRPORT RD) BRANCH',
                sol: '234',
            },
            {
                branch: 'ASABA NNEBISI ROAD BRANCH',
                sol: '238',
            },
            {
                branch: 'UGHELLI BRANCH',
                sol: '257',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'ED',
        state: 'edo',
        lgas: [
            {
                lgaCode: 'ORE',
                lga: 'OREDO',
            },
            {
                lgaCode: 'EGO',
                lga: 'EGOR',
            },
        ],
        branches: [
            {
                branch: 'MISSION ROAD BRANCH',
                sol: '009',
            },
            {
                branch: 'UGBOWO BRANCH',
                sol: '086',
            },
        ],
        region: '90j',
    },
    {
        stateCode: 'OG',
        state: 'ogun',
        lgas: [
            [
                {
                    lgaCode: 'IJB',
                    lga: 'IJEBU-ODE',
                },
                {
                    lgaCode: 'ABE2',
                    lga: 'ABEOKUTA SOUTH',
                },
                {
                    lgaCode: 'IJB2',
                    lga: 'IJEBU NORTH',
                },
                {
                    lgaCode: 'SGM',
                    lga: 'SAGAMU',
                },
                {
                    lgaCode: 'ADOO',
                    lga: 'ADO-ODO/OTA',
                },
                {
                    lgaCode: 'IFO',
                    lga: 'IFO',
                },
                {
                    lgaCode: 'BFM',
                    lga: 'OBAFEMI/OWODE',
                },
            ],
        ],
        branches: [
            'IJEBU-ODE I BRANCH',
            'ABEOKUTA BRANCH',
            'AGO-IWOYE',
            'SAGAMU BRANCH',
            'OTTA BRANCH',
            'AKUTE BRANCH',
            'AGBARA BRANCH',
            'IJEBU-ODE II BRANCH',
            'RCCG CAMP BRANCH',
        ],
        region: '80j',
    },
    {
        stateCode: 'ON',
        state: 'ondo',
        lgas: [
            {
                lgaCode: 'OND2',
                lga: 'ONDO WEST',
            },
            {
                lgaCode: 'IJE',
                lga: 'ILAJE',
            },
            {
                lgaCode: 'AKR2',
                lga: 'AKURE SOUTH',
            },
        ],
        branches: [
            {
                branch: 'ONDO BRANCH',
                sol: '012',
            },
            {
                branch: 'IGBOKODA BRANCH',
                sol: '100',
            },
            {
                branch: 'AKURE 1 BRANCH',
                sol: '133',
            },
            {
                branch: 'AKURE II BRANCH',
                sol: '279',
            },
        ],
        region: '80j',
    },
    {
        stateCode: 'AB',
        state: 'abia',
        lgas: [
            {
                lgaCode: 'ABA2',
                lga: 'ABA SOUTH',
            },
            {
                lgaCode: 'ABA1',
                lga: 'ABA NORTH',
            },
            {
                lgaCode: 'UMU1',
                lga: 'UMUAHIA NORTH',
            },
        ],
        branches: [
            {
                branch: 'ASA ROAD I BRANCH',
                sol: '013',
            },
            {
                branch: 'FAULKS ROAD BRANCH',
                sol: '045',
            },
            {
                branch: 'UMUAHIA I BRANCH',
                sol: '078',
            },
            {
                branch: 'BRASS ROAD BRANCH',
                sol: '237',
            },
            {
                branch: 'UMUAHIA II BRANCH',
                sol: '267',
            },
        ],
        region: '90j',
    },
    {
        stateCode: 'AN',
        state: 'anambra',
        lgas: [
            {
                lgaCode: 'OSH',
                lga: 'ONITSHA',
            },
            {
                lgaCode: 'NNE',
                lga: 'NNEWI',
            },
            {
                lgaCode: 'AWK',
                lga: 'AWKA',
            },
        ],
        branches: [
            {
                branch: 'ONITSHA NEW MKT RD I BRANCH',
                sol: '021',
            },
            {
                branch: 'OKO BRANCH',
                sol: '067',
            },
            {
                branch: 'AWKA BRANCH',
                sol: '094',
            },
            {
                branch: 'NNEWI I BRANCH',
                sol: '131',
            },
            {
                branch: 'ONITSHA BRIDGEHEAD BRANCH',
                sol: '140',
            },
            {
                branch: 'AWKA II BRANCH',
                sol: '243',
            },
            {
                branch: 'ONITSHA NEW MARKET RD III BRANCH',
                sol: '248',
            },
            {
                branch: 'OBOSI BRANCH',
                sol: '270',
            },
        ],
        region: '90j',
    },
    {
        stateCode: 'AK',
        state: 'akwa ibom',
        lgas: [
            {
                lgaCode: 'UYO',
                lga: 'UYO',
            },
            {
                lgaCode: 'EKE',
                lga: 'EKET',
            },
            {
                lgaCode: 'IKP',
                lga: 'IKOT EKPENE',
            },
        ],
        branches: [
            {
                branch: 'UYO ABAK ROAD BRANCH',
                sol: '028',
            },
            {
                branch: 'EKET BRANCH',
                sol: '040',
            },
            {
                branch: 'UYO, ORON BRANCH',
                sol: '042',
            },
            {
                branch: 'IKOT EKPENE I BRANCH',
                sol: '063',
            },
        ],
        region: '90j',
    },
    {
        stateCode: 'OY',
        state: 'oyo',
        lgas: [
            {
                lgaCode: 'IBD4',
                lga: 'IBADAN SOUTH WEST',
            },
            {
                lgaCode: 'IBD1',
                lga: 'IBADAN NORTH',
            },
            {
                lgaCode: 'AKY',
                lga: 'AKINYELE',
            },
        ],
        branches: [
            {
                branch: 'DUGBE BRANCH',
                sol: '029',
            },
            {
                branch: 'BODIJA BRANCH',
                sol: '058',
            },
            {
                branch: 'UCH BRANCH',
                sol: '077',
            },
            {
                branch: 'OJOO BRANCH',
                sol: '122',
            },
            {
                branch: 'MOLETE BRANCH',
                sol: '148',
            },
            {
                branch: 'AGBENI BRANCH',
                sol: '171',
            },
            {
                branch: 'AGBOWO BRANCH',
                sol: '172',
            },
            {
                branch: 'IWO ROAD BRANCH',
                sol: '177',
            },
        ],
        region: '80j',
    },
    {
        stateCode: 'BN',
        state: 'benue',
        lgas: [
            {
                lgaCode: 'MAK',
                lga: 'MAKURDI',
            },
        ],
        branches: [
            {
                branch: 'MAKURDI BRANCH',
                sol: '035',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'CR',
        state: 'cross river',
        lgas: [
            {
                lgaCode: 'CAL',
                lga: 'CALABAR',
            },
            {
                lgaCode: 'IKM',
                lga: 'IKOM',
            },
            {
                lgaCode: 'CAL1',
                lga: 'CALABAR MUNCIPALITY',
            },
        ],
        branches: [
            {
                branch: 'CALABAR BRANCH',
                sol: '038',
            },
            {
                branch: 'IKOM BRANCH',
                sol: '060',
            },
            {
                branch: 'NEW SECRETARIAT, CALABAR  BRANCH',
                sol: '235',
            },
        ],
        region: '90j',
    },
    {
        stateCode: 'BY',
        state: 'bayelsa',
        lgas: [
            {
                lgaCode: 'YEN',
                lga: 'YENEGOA',
            },
        ],
        branches: [
            {
                branch: 'YENAGOA  BRANCH',
                sol: '047',
            },
            {
                branch: 'YENAGOA II BRANCH',
                sol: '240',
            },
        ],
        region: '90j',
    },
    {
        stateCode: 'KD',
        state: 'kaduna',
        lgas: [
            {
                lgaCode: 'KAD',
                lga: 'KADUNA',
            },
            {
                lgaCode: 'SBG',
                lga: 'SABONGARI',
            },
            {
                lgaCode: 'CHK',
                lga: 'CHIKUN',
            },
            {
                lgaCode: 'KAC',
                lga: 'KACHIA',
            },
        ],
        branches: [
            {
                branch: 'AHMADU BELLO WAY KADUNA BRANCH',
                sol: '048',
            },
            {
                branch: 'KACHIA ROAD KADUNA BRANCH',
                sol: '089',
            },
            {
                branch: 'ZARIA 1 BRANCH',
                sol: '128',
            },
            {
                branch: 'KANO ROAD KADUNA BRANCH',
                sol: '184',
            },
            {
                branch: 'SABON TASHA BRANCH',
                sol: '197',
            },
            {
                branch: 'ALI AKILU ROAD KADUNA BRANCH',
                sol: '199',
            },
            {
                branch: 'KACHIA POLICE BRANCH BRANCH',
                sol: '209',
            },
            {
                branch: 'YAKUBU GOWON WAY KADUNA BRANCH',
                sol: '210',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'EK',
        state: 'ekiti',
        lgas: [
            {
                lgaCode: 'EKT',
                lga: 'EKITI',
            },
        ],
        branches: [
            {
                branch: 'ADO-EKITI BRANCH',
                sol: '050',
            },
        ],
        region: '80j',
    },
    {
        stateCode: 'EN',
        state: 'enugu',
        lgas: [
            {
                lgaCode: 'KAD',
                lga: 'KADUNA',
            },
            {
                lgaCode: 'SBG',
                lga: 'SABONGARI',
            },
            {
                lgaCode: 'CHK',
                lga: 'CHIKUN',
            },
            {
                lgaCode: 'KAC',
                lga: 'KACHIA',
            },
        ],
        branches: [
            {
                branch: 'ENUGU MKT RD BRANCH',
                sol: '057',
            },
            {
                branch: 'GARDEN AVENUE ENUGU BRANCH',
                sol: '084',
            },
            {
                branch: 'NSUKKA BRANCH',
                sol: '263',
            },
            {
                branch: 'AGBANI TOWN BRANCH',
                sol: '265',
            },
            {
                branch: 'PRESIDENTIAL ROAD BRANCH',
                sol: '278',
            },
        ],
        region: '90j',
    },
    {
        stateCode: 'OS',
        state: 'Osun',
        lgas: [
            {
                lgaCode: 'OSG',
                lga: 'OSHOGBO',
            },
            {
                lgaCode: 'ILE1',
                lga: 'ILESHA EAST',
            },
            {
                lgaCode: 'IFE1',
                lga: 'IFE CENTRAL',
            },
        ],
        branches: [
            {
                branch: 'OSOGBO I BRANCH',
                sol: '068',
            },
            {
                branch: 'ILESHA BRANCH',
                sol: '105',
            },
            {
                branch: 'ILE- IFE BRANCH',
                sol: '286',
            },
        ],
        region: '80j',
    },
    {
        stateCode: 'NI',
        state: 'niger',
        lgas: [
            {
                lgaCode: 'CHC',
                lga: 'CHANCHAGA',
            },
            {
                lgaCode: 'SLJ',
                lga: 'SULEJA',
            },
        ],
        branches: [
            {
                branch: 'MINNA 1 BRANCH',
                sol: '074',
            },
            {
                branch: 'SULEJA BRANCH',
                sol: '232',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'BO',
        state: 'borno',
        lgas: [
            {
                lgaCode: 'BNO',
                lga: 'BORNO',
            },
        ],
        branches: [
            {
                branch: 'MAIDUGURI I BRANCH',
                sol: '080',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'IM',
        state: 'imo',
        lgas: [
            {
                lgaCode: 'OWE3',
                lga: 'OWERRI MUNICIPAL',
            },
            {
                lgaCode: 'ORL',
                lga: 'ORLU',
            },
        ],
        branches: [
            {
                branch: 'WETHERAL ROAD I BRANCH',
                sol: '085',
            },
            {
                branch: 'WETHERAL ROAD II BRANCH',
                sol: '241',
            },
            {
                branch: 'ORLU II BRANCH',
                sol: '244',
            },
            {
                branch: 'MBAISE RD OWERRI BRANCH',
                sol: '251',
            },
        ],
        region: '90j',
    },
    {
        stateCode: 'PL',
        state: 'plateau',
        lgas: [
            {
                lgaCode: 'JOS2',
                lga: 'JOSNORTH',
            },
        ],
        branches: [
            {
                branch: 'BRITISH AMERICAN JUNCTION JOS',
                sol: '088',
            },
            {
                branch: 'BEACH ROAD JOS BRANCH',
                sol: '186',
            },
            {
                branch: 'MURTALA MOHAMMED WAY JOS BRANCH',
                sol: '203',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'GM',
        state: 'gombe',
        lgas: [
            {
                lgaCode: 'GOM',
                lga: 'GOMBE',
            },
            {
                lgaCode: 'FKY',
                lga: 'FUNAKAYE',
            },
        ],
        branches: [
            {
                branch: 'GOMBE 1 BRANCH',
                sol: '092',
            },
            {
                branch: 'ASHAKA BRANCH',
                sol: '130',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'AD',
        state: 'adamawa',
        lgas: [
            {
                lgaCode: 'ADM',
                lga: 'ADAMAWA',
            },
        ],
        branches: [
            {
                branch: 'YOLA BRANCH',
                sol: '093',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'KT',
        state: 'katsina',
        lgas: [
            {
                lgaCode: 'KAT',
                lga: 'KATSINA ALA',
            },
        ],
        branches: [
            {
                branch: 'MAKURDI BRANCH',
                sol: '035',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'BA',
        state: 'bauchi',
        lgas: [
            {
                lgaCode: 'BAU',
                lga: 'BAUCHI',
            },
            {
                lgaCode: 'KATG',
                lga: 'KATAGUM',
            },
        ],
        branches: [
            {
                branch: 'COMMERCIAL RD BAUCHI BRANCH',
                sol: '097',
            },
            {
                branch: 'AZARE BRANCH',
                sol: '191',
            },
            {
                branch: 'BAUCHI JOS ROAD',
                sol: '192',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'JG',
        state: 'jigawa',
        lgas: [
            {
                lgaCode: 'DUT',
                lga: 'DUTSE',
            },
        ],
        branches: [
            {
                branch: 'MISSION ROAD BRANCH',
                sol: '009',
            },
            {
                branch: 'UGBOWO BRANCH',
                sol: '086',
            },
            {
                branch: 'DUTSE 1 BRANCH',
                sol: '101',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'KG',
        state: 'kogi',
        lgas: [
            {
                lgaCode: 'LOK',
                lga: 'LOKOJA',
            },
            {
                lgaCode: 'DEK',
                lga: 'DEKINA',
            },
        ],
        branches: [
            {
                branch: 'LOKOJA 1 BRANCH',
                sol: '102',
            },
            {
                branch: 'AYINGBA BRANCH',
                sol: '224',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'EB',
        state: 'ebonyi',
        lgas: [
            {
                lgaCode: 'ABL',
                lga: 'ABAKALIKI',
            },
        ],
        branches: [
            {
                branch: 'ABAKALIKI BRANCH',
                sol: '112',
            },
        ],
        region: '90j',
    },
    {
        stateCode: 'TR',
        state: 'taraba',
        lgas: [
            {
                lgaCode: 'JAL',
                lga: 'JALINGO',
            },
        ],
        branches: [
            {
                branch: 'JALINGO BRANCH',
                sol: '116',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'YB',
        state: 'yobe',
        lgas: [
            {
                lgaCode: 'DBT',
                lga: 'DAMATURU',
            },
        ],
        branches: [
            {
                branch: 'DAMATURU 1 BRANCH',
                sol: '117',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'KW',
        state: 'kwara',
        lgas: [
            {
                lgaCode: 'ILO1',
                lga: 'ILORIN EAST',
            },
            {
                lgaCode: 'ILO2',
                lga: 'ILORIN WEST',
            },
        ],
        branches: [
            {
                branch: 'MURTALA MOHAMMED ROAD  BRANCH',
                sol: '121',
            },
            {
                branch: 'IBRAHIM TAIWO RD BRANCH',
                sol: '151',
            },
            {
                branch: 'ABDULAZEEZ ATTAH, ILORIN BRANCH',
                sol: '152',
            },
        ],
        region: '80j',
    },
    {
        stateCode: 'ZM',
        state: 'zamfara',
        lgas: [
            {
                lgaCode: 'GUS',
                lga: 'GUSAU',
            },
        ],
        branches: [
            {
                branch: 'GUSAU 1 BRANCH',
                sol: '125',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'SK',
        state: 'sokoto',
        lgas: [
            {
                lgaCode: 'SKT',
                lga: 'SOKOTO',
            },
        ],
        branches: [
            {
                branch: 'SOKOTO BRANCH',
                sol: '127',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'KB',
        state: 'kebbi',
        lgas: [
            {
                lgaCode: 'BIRNIN KEBBI',
            },
        ],
        branches: [
            {
                branch: 'KEBBI II BRANCH',
                sol: '201',
            },
        ],
        region: '60j',
    },
    {
        stateCode: 'NS',
        state: 'nasarawa',
        lgas: [
            {
                lgaCode: 'LAF',
                lga: 'LAFIA',
            },
            {
                lgaCode: 'KEF',
                lga: 'KEFFI',
            },
            {
                lgaCode: 'KAR',
                lga: 'KARU',
            },
        ],
        branches: [
            {
                branch: 'LAFIA II BRANCH',
                sol: '202',
            },
            {
                branch: 'SABON TASHA KEFFI BRANCH',
                sol: '221',
            },
            {
                branch: 'KARU BRANCH',
                sol: '285',
            },
        ],
        region: '60j',
    },
];

export { IDProfileServices, colourOptions, countries, states };

export const timeout = 1000 * 60 * 5;
export const debounce = 500;

export const businessRegistration = 'Business Registration';

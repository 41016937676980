import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { useNavigate } from 'react-router-dom';
import { persistActionCreators } from '../store/actions/helper';
import { notifyError, notifySuccess } from '../utils/helpers';
import { consultantActionCreators } from '../store/actions/vendor';
import { decodeJwt } from '../utils/helpers/security';
import { RootState } from '../store';

const useLogout = () => {
    const customDispatch = useDispatch();
    const { logout } = bindActionCreators(consultantActionCreators, customDispatch);
    const { persistData } = bindActionCreators(persistActionCreators, customDispatch);

    const { consultantAuth } = useSelector((store: RootState) => store);

    const logoutAuth = async () => {
        try {
            const { accessToken } = consultantAuth.consultantData;

            if (accessToken) {
                const { sub }: { sub: string } = (await decodeJwt(accessToken)) as any;

                persistData(false);
                const res = await logout(sub);

                if (res?.error) {
                    notifyError(res?.message);
                }
                if (!res.error) {
                    notifySuccess(res?.message);
                    // window.location.replace('/vendor/login');
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return logoutAuth;
};

export default useLogout;

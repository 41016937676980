import { Dispatch } from 'redux';
import * as actionTypes from './actionTypes';

export const uiStartLoading = () => async (dispatch: DispatchType) => {
    dispatch({
        type: actionTypes.UI_START_LOADING,
    });
};

export const uiStopLoading = () => async (dispatch: Dispatch<Action>) => {
    dispatch({
        type: actionTypes.UI_STOP_LOADING,
    });
};
